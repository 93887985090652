.active.nav-link {
    font-weight: bold;
}

div.playbook {
    border: 1px solid #6f7e80;
}

div.playbook div.panel {
    background-color: #1a4550;
}

div.playbook div.container {
    padding: 0px;
}

div.playbook {
    font-size: 80%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Kirsty;
}

section.editable {
    text-decoration: underline;
}

@font-face {
    font-family: 'Kirsty';
    font-style: normal;
    font-weight: normal;
    src: local('Kirsty'), url('./fonts/Kirsty.woff') format('woff');
}


@font-face {
    font-family: 'Kirsty Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Kirsty Italic'), url('./fonts/KirstyItalic.woff') format('woff');
}


@font-face {
    font-family: 'Kirsty Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Kirsty Bold'), url('./fonts/KirstyBold.woff') format('woff');
}


@font-face {
    font-family: 'Kirsty Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Kirsty Bold Italic'), url('./fonts/KirstyBoldItalic.woff') format('woff');
}